import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { News } from "../models/news";
import { NewsResponse } from "../models/news-response";

@injectable()
export class UmbracoApiClient extends ApiClientBase {
    public name = "UmbracoApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getNovice(page: number, take: number): Promise<NewsResponse> {
        return await this._callApi('/umbraco/novice/list?page=' + page + '&take=' + take, 'GET', null, false, "");
    }

    public async getNovicaById(id: number): Promise<News> {
        return await this._callApi('/umbraco/novica/' + id, 'GET', null, false, "");
    }

    public async getNovicaByUrl(url: string): Promise<News> {
        return await this._callApi('/umbraco/novica-by-url/' + url, 'GET', null, false, "");
    }

    public async getNasveti(page: number, take: number): Promise<any> {
        return await this._callApi('/umbraco/nasveti/list?page=' + page + '&take=' + take, 'GET', null, false, "");
    }

    public async getNasvetById(id: number): Promise<void> {
        return await this._callApi('/umbraco/nasvet/' + id, 'GET', null, false, "");
    }

    public async getNasvetByUrl(url: string): Promise<any> {
        return await this._callApi('/umbraco/nasvet-by-url/' + url, 'GET', null, false, "");
    }

    public async getBlogi(): Promise<void> {
        return await this._callApi('/umbraco/blogi/list', 'GET', null, false, "");
    }

    public async getBlogById(id: number): Promise<void> {
        return await this._callApi('/umbraco/blog/' + id, 'GET', null, false, "");
    }

    public async getKategorije(): Promise<void> {
        return await this._callApi('/umbraco/kategorije/list', 'GET', null, false, "");
    }

    public async getKategorijaById(id: number): Promise<void> {
        return await this._callApi('/umbraco/kategorija/' + id, 'GET', null, false, "");
    }

    public async getPogostaVprasanja(): Promise<any> {
        return await this._callApi('/umbraco/pogosta-vprasanja/list', 'GET', null, false, "");
    }

    public async getPogostoVprasanjeById(id: number): Promise<void> {
        return await this._callApi('/umbraco/pogosto-vprasanje/' + id, 'GET', null, false, "");
    }

    public async getOIzzivuPageData(): Promise<any> { 
        return await this._callApi('/umbraco/o-izzivu', 'GET', null, false, "");
    }

    public async getStatistikaIzzivovPageData(): Promise<any> {
        return await this._callApi('/umbraco/statistika-izzivov', 'GET', null, false, "");
    }

    public async getIzzivi(statistika: boolean): Promise<any> {
        return await this._callApi('/umbraco/izzivi/get/' + statistika, 'GET', null, false, "");
    }

    public async getPogojiUporabe(): Promise<any> {
        return await this._callApi('/umbraco/pogoji-uporabe', 'GET', null, false, "");
    }

    public async getPolitikaZasebnosti(): Promise<any> {
        return await this._callApi('/umbraco/politika-zasebnosti', 'GET', null, false, "");
    }

    public async getPiskotki(): Promise<any> {
        return await this._callApi('/umbraco/piskotki', 'GET', null, false, "");
    }

    public async getPogojiSodelovanja(): Promise<any> {
        return await this._callApi('/umbraco/pogoji-sodelovanja', 'GET', null, false, "");
    }

    public async getHomePage(): Promise<any> {
        return await this._callApi('/umbraco/home', 'GET', null, false, "");
    }

    public async getNagradePage(): Promise<any> {
        return await this._callApi('/umbraco/nagradePage', 'GET', null, false, "");
    }

    public async getKakoZacetiPage(): Promise<any> {
        return await this._callApi('/umbraco/kakoZacetiPage', 'GET', null, false, "");
    }

    public async getZaMedijePage(): Promise<any> {
        return await this._callApi('/umbraco/zaMedijePage', 'GET', null, false, "");
    }

    public async getPartnerji(): Promise<any> {
        return await this._callApi('/umbraco/partnerji/list', 'GET', null, false, "");
    }

    public async getPodporniki(): Promise<any> {
        return await this._callApi('/umbraco/podporniki/list', 'GET', null, false, "");
    }

    public async getKotDelodajalecPage(): Promise<any> {
        return await this._callApi('/umbraco/kotDelodajalecPage', 'GET', null, false, "");
    }

    public async getKotPodpornikPage(): Promise<any> {
        return await this._callApi('/umbraco/kotPodpornikPage', 'GET', null, false, "");
    }

    public async getAnketoNavad(): Promise<any> {
        return await this._callApi('/umbraco/anketa-navad', 'GET', null, false, "");
    }    
} 