import { inject, injectable } from "inversify";
import { Authenticator } from "../authentication/authenticator";
import { Configuration } from "../configuration/configuration";
import { ErrorFactory } from "../error-handling/error-factory";
import axios from "axios";

@injectable()
export abstract class ApiClientBase {
    private _apiBaseUrl: string;
    protected _authenticator: Authenticator;

    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        this._apiBaseUrl = configuration.app.apiBaseUrl;
        this._authenticator = authenticator;
    }

    protected async _callApi(path: string, method: string, dataToSend?: any,
        redirectOnMissingToken: boolean = false,
        dataType: string = "json",
        done: { (data: any, status: string, xhr: JQueryXHR): void } | null = null,
        fail: { (data: any, status: string, xhr: JQueryXHR): void } | null = null,
        prepareRequest: ((request: JQueryAjaxSettings) => void) | null = null
        ): Promise<any> {

        // Get the full path
        const url = `${this._apiBaseUrl}${path}`;
        // Get the access token, and if it does not exist a login redirect will be triggered
        let token = await this._authenticator.getAccessToken(redirectOnMissingToken);

        try {

            // Call the API
            return await this._callApiWithToken(url, method, dataToSend, token, dataType, done, fail, prepareRequest);

        } catch (error) {
            var error1 = error as JQuery.jqXHR;
            // Report Ajax errors if this is not a 401
            if (!this._isApi401Error(error1)) {
                throw ErrorFactory.getFromHttpError(error1, url, 'Web API');
            }
            if (redirectOnMissingToken) {
                try {
                    // Try to refresh the access token
                    token = await this._authenticator.refreshAccessToken();

                    // Call the API again
                    return await this._callApiWithToken(url, method, dataToSend, token, dataType, done, fail, prepareRequest);

                } catch (error2) {

                    console.log(`Error: ${error2}`);
                    // Report Ajax errors for the retry
                    throw ErrorFactory.getFromHttpError(error2, url, 'Web API');
                }
            }
            else {
                console.log('No token, redirecting to login');
                this._authenticator._startLogin();
            }
        }
    }

    protected async _callApiWithToken(
        url: string,
        method: string,
        dataToSend: any,
        accessToken: string,
        dataType: string = "json",
        done: { (data: any, status: string, xhr: JQueryXHR): void } | null = null,
        fail: { (data: any, status: string, xhr: JQueryXHR): void } | null = null,
        prepareRequest: ((request: JQueryAjaxSettings) => void) | null = null

    ): Promise<any> {
        var ajaxOptions: JQueryAjaxSettings = {
            url: url,
            type: method,
            crossDomain: true,
            data: dataToSend,
            dataType: dataType,
            contentType: "application/json; charset=utf-8", //Dodano zaradi dodajanja okvar, ?e kdaj pride tukaj do napake se lahko doda optional parameter
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }, xhrFields: {
                withCredentials: true
            },
        };
        if (dataType === "binary") {
            ajaxOptions.xhrFields = {
                responseType: 'arraybuffer', 
                withCredentials: true
                
            };
        }

        if (prepareRequest) {
            prepareRequest(ajaxOptions);
        }
        var xhr = $.ajax(ajaxOptions);
        if (done != null) {
            xhr.done(done as any);
        }
        if (fail != null) {
            xhr.fail(fail as any); 
        }

        const response = await xhr;
        return response;
    }

    protected async _createAjaxOptionsWithToken(
        path: string,
        method: string,
        dataToSend: any,
        redirectOnMissingToken: boolean = true,
        dataType: string = "json",
        done: JQuery.Ajax.SuccessCallback<any> | undefined = undefined,
        fail: JQuery.Ajax.ErrorCallback<any> | undefined = undefined,
        prepareRequest: ((request: JQueryAjaxSettings)=>void) | null = null

    ): Promise<JQueryAjaxSettings> {
        // Get the access token, and if it does not exist a login redirect will be triggered
        let accessToken = await this._authenticator.getAccessToken(redirectOnMissingToken);

        // Get the full path
        const url = `${this._apiBaseUrl}${path}`;

        var ajaxOptions: JQueryAjaxSettings = {
            url: url,
            type: method,
            crossDomain: true,
            data: dataToSend,
            cache: false,
            dataType: dataType,
            contentType: "application/json; charset=utf-8", //Dodano zaradi dodajanja okvar, ?e kdaj pride tukaj do napake se lahko doda optional parameter
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        };
        if (dataType === "binary") {
            ajaxOptions.xhrFields = { responseType: 'arraybuffer' };
        }
        ajaxOptions.success = done;
        ajaxOptions.error = (jqXHR: JQuery.jqXHR, textStatus: JQuery.Ajax.ErrorTextStatus, errorThrown: string) => {
            fail?.apply(fail, [jqXHR, textStatus, errorThrown]);
            // Report Ajax errors if this is not a 401
            if (!this._isApi401Error(jqXHR)) {
                throw ErrorFactory.getFromHttpError(jqXHR, url, 'Web API');
            }
            if (redirectOnMissingToken) {
                this._authenticator.refreshAccessToken()
                    .then(() => {
                        this._callApiWithToken(url, method, dataToSend, accessToken, dataType, done as any, fail as any)
                            .catch((error2) => {
                                throw ErrorFactory.getFromHttpError(error2, url, 'Web API');
                            })
                    })
                    .catch((error1) => {
                        throw ErrorFactory.getFromHttpError(error1, url, 'Web API');

                    });
            }
        };
        if (prepareRequest) {
            ajaxOptions.beforeSend = (_jqXhr, settings) => {
                prepareRequest(settings);
            };
        }

        return ajaxOptions;
    }

    protected async _callApiWithFormData(
        path: string,
        method: string = "POST", // FormData is typically sent through POST requests
        formData: FormData,
        redirectOnMissingToken: boolean = false
    ): Promise<any> {
        const url = `${this._apiBaseUrl}${path}`;
        let token = await this._authenticator.getAccessToken(redirectOnMissingToken);
    
        // Prepare the request options
        const fetchOptions: RequestInit = {
            method: method,
            headers: new Headers({
                'Authorization': `Bearer ${token}`,
                // Don't set 'Content-Type' to 'multipart/form-data' here.
                // The browser will set it automatically, including the boundary parameter.
            }),
            body: formData, // Directly use FormData as the body of the request
            credentials: 'include', // For cookies, if needed
        };
    
        try {
            const response = await fetch(url, fetchOptions);
            if (!response.ok) {
                // Handle HTTP errors
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return await response.json(); // Assuming the server responds with JSON
        } catch (error) {
            console.error("Fetching error:", error);
            // Re-try or handle error as needed
            throw error; // Re-throw the error for further handling if necessary
        }
    }

    private _isApi401Error(error: JQuery.jqXHR): boolean {
        if ((error.responseJSON && error.responseJSON.status === 401) || error.status === 401) {
            return true;
        }

        return false;
    }

    protected async _downloadFile(path: string): Promise<Blob> {
        let token = await this._authenticator.getAccessToken(true);  // Ensure valid token
        const url = `${this._apiBaseUrl}${path}`;

        try {
            const response = await axios({
                url,
                method: 'GET',
                responseType: 'blob',  // Important for handling binary data
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });

            if (response.status === 200) {
                return response.data;  // Returns a Blob which can be handled on the client-side
            } else {
                throw ErrorFactory.getFromHttpError(`Failed to download file with status: ${response.status}`, url, 'Web API');
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                // Handle 401 error
                throw ErrorFactory.getFromHttpError(error, url, 'Web API');
            }
            throw ErrorFactory.getFromHttpError(error, url, 'Web API');
    }
}
}