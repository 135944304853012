import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { ErrorLoadingView } from "../../views/error-loading-view";
import { PolitikaZasebnostiView } from "../../views/politika-zasebnosti-view";
import { UmbracoApiClient } from "../clients/umbraco-api-client";

@injectable()
export class PolitikaZasebnostiPage extends PageBase
{   
    public name = "PolitikaZasebnostiPage";
    private _apiClient: UmbracoApiClient;
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _homeView: PolitikaZasebnostiView;
    private _translationService: TranslationService;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,        
        @inject("UmbracoApiClient") apiClient: UmbracoApiClient,
        @inject("Notyf") notyf: Notyf,
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._router = router;
        this._translationService = translationService;
        this._notyf = notyf;
        this._apiClient = apiClient;
        this._homeView = new PolitikaZasebnostiView(this._apiClient);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (url.pathname != "" && !url.pathname.startsWith("/politika-zasebnosti")) {
            return true;
        }
        console.log(this._mainLayoutProvider);
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            //var translations = this._translationService.currentTranslations;
            mainLayout.pageTitle = "Politika zasebnosti";
            mainLayout.htmlTitle = "Politika zasebnosti";
            //mainLayout.headerUserViewHide();
            //mainLayout.profileSidebarViewShow();
            await this._homeView.load();
            this._initHeaderActiveTab();
            mainLayout.showFooter();
            mainLayout.showHeader();
        } catch (e) {
            // Report failures
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }

    private _initHeaderActiveTab() {
        var tabOIzzivu = $(".tab-o-izzivu");
        tabOIzzivu.removeClass("active");

        var tabNagrade = $(".tab-nagrade");
        tabNagrade.removeClass("active");

        var tabKakoZaceti = $(".tab-kako-zaceti");
        tabKakoZaceti.removeClass("active");

        var tabNasveti = $(".tab-nasveti");
        tabNasveti.removeClass("active");

        var tabNovice = $(".tab-novice");
        tabNovice.removeClass("active");

        var tabZaMedije = $(".tab-za-medije");
        tabZaMedije.removeClass("active");

        var tabStatistika = $(".tab-statistika");
        tabStatistika.removeClass("active");

        var tabIzvozPodatkov = $(".tab-izvoz-podatkov");
        tabIzvozPodatkov.removeClass("active");
    }
}