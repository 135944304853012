import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { ErrorLoadingView } from "../../views/error-loading-view";
import { CurrentUserAccessor } from "../utilities/current-user-accessor";
import { ContentView } from "../../views/content-view";
import { HomeApiClient } from "../clients/home-api-client";
import { UmbracoApiClient } from "../clients/umbraco-api-client";

@injectable()
export class ContentPage extends PageBase
{   
    public name = "ContentPage";
    private _homeApiClient: HomeApiClient;
    private _umbracoApiClient: UmbracoApiClient;
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _homeView: ContentView;
    private _translationService: TranslationService;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,        
        @inject("HomeApiClient") homeApiClient: HomeApiClient,
        @inject("UmbracoApiClient") umbracoApiClient: UmbracoApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._router = router;
        this._translationService = translationService;
        this._notyf = notyf;
        this._homeApiClient = homeApiClient;
        this._umbracoApiClient = umbracoApiClient;
        this._homeView = new ContentView(this._homeApiClient, this._umbracoApiClient, translationService, currentUserAccessor);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (url.pathname != "" && !url.pathname.startsWith("/content")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            var translations = this._translationService.currentTranslations;
            mainLayout.pageTitle = translations.Desktop;
            mainLayout.htmlTitle = translations.Desktop;
            //mainLayout.headerUserViewHide();
            //mainLayout.profileSidebarViewShow();
            await this._homeView.load();
        } catch (e) {
            // Report failures
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}