import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { HelpModel } from "../models/help-model";

@injectable()
export class MailchimpApiClient extends ApiClientBase {
    public name = "MailchimpApiClient";
    public constructor(

        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async mailchimpSubscribe(email: string): Promise<any> {
        return await this._callApi('/mailchimp/submit?email=' + email, 'POST', null) as any;
    }

    public async submitHelp(model: HelpModel): Promise<any> {
        return await this._callApi('/mailchimp/SubmitHelp', 'POST', JSON.stringify(model)) as any;
    }
}