import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./news-view.html";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { UmbracoApiClient } from "../ts/clients/umbraco-api-client";
import { News } from "../ts/models/news";
import * as moment from "moment";
import { NewsResponse } from "../ts/models/news-response";

@injectable()
export class NewsView {
    private _apiClient: UmbracoApiClient;
    private _translationService: TranslationService;
    private _translations: any;
    private _currentPage: number;
    private _maxPages: number
    private _news: Array<News>;

    public constructor(desktopApiClient: UmbracoApiClient,
        translationService: TranslationService,
        private currentUserAccessor: CurrentUserAccessor) {        
        this._apiClient = desktopApiClient;
        this._translationService = translationService;
        this._translations = null;
        this._currentPage = 0;
        this._maxPages = 0;
        this._news = new Array<News>();
    }

    public async load(): Promise<void> {
        try {
            this._currentPage = this._getCurrentPage();
            var response = await this._apiClient.getNovice(this._currentPage - 1, 12);
            this._news = response.Novice;
            this._maxPages = response.MaxPages;
            await this._renderData(this._news, response);                                    

        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private _getCurrentPage(): number {
        var url = window.location.href;
        var urlData = url.split("/");
        var page = Number(urlData[urlData.length - 1]);
        if (isNaN(page) || page == 0) {
            return 1;
        }
        return page;
    }

    private getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'sl';
        moment.locale(userLocale);
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        return {
            dayFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('dddd');
                };
            },
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            },
            dateFormat2: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('D.M.');
                };
            },
            fullDateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('yyyy-MM-DD');
                };
            },
            decimalFormat: function () {
                return function (timestamp: any, render: any) {
                    var decimal = render(timestamp).trim() as number;
                    return Number(decimal).toLocaleString('sl-SI');
                };
            }
        };
    }

    private async _renderData(data: Array<News>, response: NewsResponse): Promise<void> {
        // Build a view model from the API data
        this._translations = this._translationService.currentTranslations;
        var currentUser = await this.currentUserAccessor.getUser();

        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            "imageKuponUrl": "../img/icon-kuponi.png",
            "imageKodaUrl": "../img/koda.jpg",
            "lastNews": data[0],
            "news": data,
            "formators": this.getFormators(),
            translations: this._translations,
            currentUser: currentUser,
            obstajaVecNovic: this._maxPages == this._currentPage ? false : true
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);

        // Render pagination
        this._renderPagination();

        this._initButtons();

        // Set SEO
        $("#meta-description")[0].setAttribute("content", response.SeoDescription);
        $("#meta-og-title")[0].setAttribute("content", response.SeoTitle);
        $("#meta-og-description")[0].setAttribute("content", response.SeoDescription);
        $("#meta-og-image")[0].setAttribute("content", response.SeoImage);
        $("#meta-twitter-title")[0].setAttribute("content", response.SeoTitle);
        $("#meta-twitter-description")[0].setAttribute("content", response.SeoDescription);
        $("#meta-twitter-image")[0].setAttribute("content", response.SeoImage);
        var currentUrl = window.location.href;
        $("#meta-og-url")[0].setAttribute("content", currentUrl);
        $("#meta-twitter-url")[0].setAttribute("content", currentUrl);
    } 

    private _renderPagination(): void {
        const templateString = `
                <div class="pagination">
                    <a role="button" id="btn-previous-page">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#79B4A9" class="bi bi-chevron-left" viewBox="0 0 16 16" stroke="#79B4A9" stroke-width="1">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                        </svg>
                    </a>
                    <%#pages%>
                        <a 
                            <%#isActive%> class="active" <%/isActive%> 
                            <%#isNumber%> href="#/novice/<%{pageNumber}%>" <%/isNumber%>><%{pageNumber}%>
                        </a>
                    <%/pages%>
                    <a role="button" id="btn-next-page">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#79B4A9" class="bi bi-chevron-right" viewBox="0 0 16 16" stroke="#79B4A9" stroke-width="1">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </a>
                </div>
             `;
        var customTags: any = ['<%', '%>'];
        var pages = this._getPaginationDetails();
        var paginationHtml = Mustache.render(
            templateString,
            {
                "pages": pages
            },
            undefined,
            customTags
        );
        $("#pagination-div").html(paginationHtml);

        var previousPage = $("#btn-previous-page");
        previousPage.on("click", () => {
            if (this._currentPage - 1 == 0) {
                return;
            }
            this._currentPage -= 1;
            window.location.href = "#/novice/" + this._currentPage;
        });

        var nextPage = $("#btn-next-page");
        nextPage.on("click", () => {
            if (this._currentPage + 1 > this._maxPages) {
                return;
            }
            this._currentPage += 1;
            window.location.href = "#/novice/" + this._currentPage;
        });
    }

    private _getPaginationDetails() {
        var result = new Array<any>();
        // Previous pages
        var page = this._currentPage - 1;
        var previousPages = 2;
        while (previousPages > 0 && page > 0) {
            result.unshift({
                pageNumber: page.toString(),
                isActive: false,
                isNumber: true
            });
            page -= 1;
            previousPages -= 1;
        }
        // First page
        if (page == 1) {
            result.unshift({
                pageNumber: "1",
                isActive: false,
                isNumber: true
            });
        }
        else if (page > 1) {
            result.unshift({
                pageNumber: "...",
                isActive: false,
                isNumber: false
            });
            result.unshift({
                pageNumber: "1",
                isActive: false,
                isNumber: true
            });
        }
        // Current page
        var pageModel = {
            pageNumber: this._currentPage.toString(),
            isActive: true,
            isNumber: true
        };
        result.push(pageModel);
        // Next pages
        var nextPages = 2;
        page = this._currentPage + 1;
        while (nextPages > 0 && page <= this._maxPages) {
            result.push({
                pageNumber: page.toString(),
                isActive: false,
                isNumber: true
            });
            page += 1;
            nextPages -= 1;
        }
        // Last page
        if (this._maxPages - page == 0) {
            result.push({
                pageNumber: this._maxPages.toString(),
                isActive: false,
                isNumber: true
            });
        }
        else if (this._maxPages - page >= 1) {
            result.push({
                pageNumber: "...",
                isActive: false,
                isNumber: false
            });
            result.push({
                pageNumber: this._maxPages.toString(),
                isActive: false,
                isNumber: true
            });
        }
        return result;
    }

    private _initButtons(): void {
        var btnLoadMore = $("#btn-load-more");
        btnLoadMore.on("click", async (ev) => {
            await this._loadMoreNews(ev);
        });
    }

    private async _loadMoreNews(ev: any): Promise<void> {
        ev.preventDefault();
        this._currentPage += 1;

        // Spremenimo url-ja strani
        var newUrl = "#/novice/" + this._currentPage.toString();
        history.pushState({}, "", newUrl);

        // Preberemo dodatne novice
        var response = await this._apiClient.getNovice(this._currentPage - 1, 12);
        var newNews = response.Novice;
        const templateString = `
                <%#news%>
                    <div class="col-12 col-md-6 col-xl-3">
                        <div class="news-block">
                            <a href="#/novica/<%{url}%>"><img src="<%{Slika}%>" class="img-fluid nb-image"></a>
                            <div class="news-content">
                                <div class="datum"><%#formators%><%#dateFormat%><%DatumNovice%><%/dateFormat%><%/formators%></div>
                                <h5 class="fw-bold"><a href="#/novica/<%{url}%>"><%{Naslov}%></a></h5>
                                <p><%{Povzetek}%></p>
                            </div>
                        </div>
                    </div>
                <%/news%>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "news": newNews,
                "formators": this.getFormators()
            },
            undefined,
            customTags
        );
        $("#novice-div").append(html);

        // Na novo renderiramo paginacijo
        this._renderPagination();

        // če ni več novic, skrijemo gumba
        if (this._currentPage == response.MaxPages) {
            $("#btn-load-more").hide();
        }
    }
}
