import { inject, injectable } from "inversify";
import { Notyf } from "notyf";
import { Authenticator } from "../../../skupno/src/ts/authentication/authenticator";
import { ErrorConsoleReporter } from "../../../skupno/src/ts/error-handling/error-console-reporter";
import { Router } from "../../../skupno/src/ts/routing/router";
import { HtmlStorageHelper } from "../../../skupno/src/ts/utils/html-storage-helper";
//import { CuratorApiClient } from "../ts/clients/curator-api-client";
import { MailchimpApiClient } from "../ts/clients/mailchimp-api-client";
//import { NotificationsApiClient } from "../ts/clients/notifications-api-client";
import { UmbracoApiClient } from "../ts/clients/umbraco-api-client";
import { UserApiClient } from "../ts/clients/users-api-client";
import { WebPushApiClient } from "../ts/clients/web-push-api-client";
import { CookieManager } from "../ts/utilities/cookie-manager";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { NotificationsManager } from "../ts/utilities/notifications-manager";
import { RegionEmployerManager } from "../ts/utilities/region-employer-manager";
import { ErrorView } from "./error-view";
import { FooterView } from "./footer-view";
import { FullscreenLoader } from "./fullscreen-loader";
import { HeaderView } from "./header-view";

/*
 * Opisuje DOM dokument v katerem teče aplikacija.
 */
@injectable()
export class MainLayout {
    public name = "MainLayout";
	private _headElement: Element;
	private _htmlTitleElement: Element | null;
	private _errorView: ErrorView;
	private _authenticator: Authenticator;
    private _router: Router;
	private _headerUserView: HeaderView;
	private _footerView: FooterView;
	private _notyf: Notyf;
	private _fullscreenLoader: FullscreenLoader;
	private _mailchimpApiClient: MailchimpApiClient;
	private _userApiClient: UserApiClient;
	private _umbracoApiClient: UmbracoApiClient;
	//private _curatorApiClient: CuratorApiClient;
	private _cookieManager: CookieManager;
	private _webPushApiClient: WebPushApiClient;
	private _notificationsManager: NotificationsManager;
	private _regionEmployerManager: RegionEmployerManager;
	/*private _notificationsApiClient: NotificationsApiClient;*/
	private _currentUserAccessor: CurrentUserAccessor;

	get htmlTitle(): string | null {
		if (this._htmlTitleElement != null) {
			return this._htmlTitleElement.innerHTML;
		}
		return null;
	}

	set htmlTitle(value: string | null) {
		if (this._htmlTitleElement != null) {
			this._htmlTitleElement.innerHTML = value || "";
		}
    }

    set pageTitle(title: string | null) {
        if (this._headerUserView != null) {
            this._headerUserView.setTitle(title || "");
        }
    }

    showHeader():void {
        this._headerUserView.visible = true;
    }
    hideHeader(): void {
        this._headerUserView.visible = false;
	}

	showFooter(): void {
		this._footerView.visible = true;
	}
	hideFooter(): void {
		this._footerView.visible = false;
	}

	async reloadHeaderData(): Promise<void> {
		await this._headerUserView.load();
	}

	get errorView(): ErrorView {
		return this._errorView;
    }

	public constructor(@inject("Document") document: Document,
		@inject("Authenticator") authenticator: Authenticator,
        @inject("Router") router: Router,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor,
		@inject("Notyf") notyf: Notyf,
		@inject("FullscreenLoader") fullscreenLoader: FullscreenLoader,
		@inject("MailchimpApiClient") mailchimpApiClient: MailchimpApiClient,
		@inject("UserApiClient") userApiClient: UserApiClient,
		@inject("UmbracoApiClient") umbracoApiClient: UmbracoApiClient,
		@inject("WebPushApiClient") webPushApiClient: WebPushApiClient,
		/*@inject("NotificationApiClient") notificationsApiClient: NotificationsApiClient*/
		//@inject("CuratorApiClient") curatorApiClient: CuratorApiClient,
	) {
		this._authenticator = authenticator;
        this._router = router;
        this._errorView = new ErrorView();
        this._setupCallbacks();
		this._notyf = notyf;
		this._fullscreenLoader = fullscreenLoader;
		this._mailchimpApiClient = mailchimpApiClient;
		this._userApiClient = userApiClient;
		this._umbracoApiClient = umbracoApiClient;
		this._cookieManager = new CookieManager();
		this._webPushApiClient = webPushApiClient;
		//this._curatorApiClient = curatorApiClient;
		this._notificationsManager = new NotificationsManager();
		this._regionEmployerManager = new RegionEmployerManager(this._userApiClient, this._notyf);

		this._headElement = document.getElementsByTagName("head")[0] || null;
		if (this._headElement != null) {
			this._htmlTitleElement = this._headElement.getElementsByTagName("title")[0] || null;
		}
		else {
			this._htmlTitleElement = null;
		}
		this._headerUserView = new HeaderView(document.getElementById("header-view") as HTMLElement, currentUserAccessor, authenticator, this._userApiClient, this._notyf, this._fullscreenLoader);
		this._footerView = new FooterView(this._mailchimpApiClient, this._umbracoApiClient, this._notyf, this._fullscreenLoader, document.getElementById("footer-view") as HTMLElement);

		this._currentUserAccessor = currentUserAccessor;
    }

	public async initialize(): Promise<void> {
		this._errorView.load();
		await this._headerUserView.load();
		await this._footerView.load();
		var data = await this._currentUserAccessor.getUser()

		function isiPhone() {
			return /iPhone/i.test(navigator.userAgent);
		}

		if (isiPhone()) {
			$(".modal-content").addClass("ajfon");
		}
		// Cookie modal and web notifications
		$(window).one("router:pagechanged", async () => {
			// debugger
			var izbraniPiskotki = this._cookieManager.cookieZeShranjen(".IzbraniPiskotki");
			if (!izbraniPiskotki) {
				this._cookieManager.initCookieModal();
				$("#cookieModal").modal("show");
				var self = this;
				$('#cookieModal').on('hidden.bs.modal', async function () {
					await self._notificationsManager.initNotificationsModal(self._userApiClient, self._webPushApiClient);
					if ($('#notifModal').is(':visible')) {
						$('#notifModal').on('hidden.bs.modal', async function () {
							if (window.location.hash.startsWith("#/profil")) {
								if(!data.showUserExistingForm) {
									self._regionEmployerManager._updateRegionAndEmployerModal();
								}
							}
						});
					}
					else {
						if (window.location.hash.startsWith("#/profil")) {
							if(!data.showUserExistingForm) {
								self._regionEmployerManager._updateRegionAndEmployerModal();
							}
						}
					}
				});
			}
			else {
				await this._notificationsManager.initNotificationsModal(this._userApiClient, this._webPushApiClient);
				var self = this;
				if ($('#notifModal').is(':visible')) {
					$('#notifModal').on('hidden.bs.modal', async function () {
						if (window.location.hash.startsWith("#/profil")) {
							if(!data.showUserExistingForm ) {
								self._regionEmployerManager._updateRegionAndEmployerModal();
							}
						}
					});
				}
				else {
					if (window.location.hash.startsWith("#/profil")) {
						
						if(data && !data.showUserExistingForm)
						{
							self._regionEmployerManager._updateRegionAndEmployerModal();
						}
						else {
							this._authenticator._userManager.removeUser();
						}
					}
				}
			}
		});		
	}

	/*
	 * The home button moves to the home view but also deals with error recovery
	 */
	private async _onHome(): Promise<void> {
		document.location = "#/";
	}

	/*
	 * This method is for testing only, to make the access token in storage act like it has expired
	 */
	public async expireAccessToken(): Promise<void> {
		const user = await this._authenticator._userManager.getUser();
		if (user) {
			// Add a character to the signature to make it fail validation
			user.access_token = `${user.access_token}x`;
			this._authenticator._userManager.storeUser(user);
		}
	}

	/*
	 * Force both API calls when reload is clicked
	 */
	private async _onReloadData(): Promise<void> {
		this._router.refresh();
	}

	/*
	 * Perform a logout request
	 */
	private async _onLogout(): Promise<void> {
		try {
			// Start the logout redirect
			await this._authenticator!.startLogout();

		} catch (e) {
			// On error, only output logout errors to the console, then move to the logged out view
			ErrorConsoleReporter.output(e);
			location.hash = '#loggedout';
		}
	}

	/*
	 * Handle logout notifications from other browser tabs
	 */
	private _onStorageChange(event: StorageEvent): void {
		if (HtmlStorageHelper.isMultiTabLogoutEvent(event)) {
			this._authenticator!.onExternalLogout();
			location.hash = '#loggedout';
		}
	}

	/*
	 * Force a new access token to be retrieved
	 */
	private async _onExpireToken(): Promise<void> {
		await this.expireAccessToken();
	}

	/*
	* Plumbing to ensure that the this parameter is available in async callbacks
	*/
	private _setupCallbacks(): void {
		this._onHome = this._onHome.bind(this);
		this._onReloadData = this._onReloadData.bind(this);
		this._onLogout = this._onLogout.bind(this);
		this._onStorageChange = this._onStorageChange.bind(this);
		this._onExpireToken = this._onExpireToken.bind(this);
	}
}