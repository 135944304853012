import { OidcClientSettings, UserManager, WebStorageStateStore } from "oidc-client";
import { ErrorCodes } from "../error-handling/error-codes";
import { ErrorFactory } from "../error-handling/error-factory";
import { UserInfo } from "./user-info";
import { HtmlStorageHelper } from "../utils/html-storage-helper";

export class Authenticator {

    public name = "Authenticator";
    public readonly _userManager: UserManager;
    public constructor(config: OidcClientSettings) {
        // Create the user manager
        this._userManager = new UserManager({
            authority: config.authority,
            client_id: config.client_id,
            client_secret: config.client_secret,
            redirect_uri: config.redirect_uri,
            response_type: config.response_type,
            scope: config.scope,
            post_logout_redirect_uri: config.post_logout_redirect_uri,
            userStore: new WebStorageStateStore({ store: window.localStorage })
        });
        //this._userManager = new UserManager(config);
    }

    public async _handleLoginResponse(): Promise<void> {
        
        // If the page loads with a state query parameter we classify it as an OAuth response
        const urlData = new URL(location.href);
        const state = urlData.searchParams.get("state");
        if (state) {
            // Only try to process a login response if the state exists
            const storedState = await this._userManager.settings.stateStore?.get(state);
            if (storedState) {

                let redirectLocation = '#';
                try {

                    // Handle the login response
                    const user = await this._userManager.signinRedirectCallback(location.href);

                    // We will return to the app location before the login redirect
                    redirectLocation = "/" + (user.state as any).hash;

                    HtmlStorageHelper.isLoggedIn = true;
                    HtmlStorageHelper.multiTabLogout = false;

                } catch (e) {
                    // Handle and rethrow OAuth response errors
                    throw ErrorFactory.getFromLoginOperation(e, ErrorCodes.loginResponseFailed);

                } finally {
                    // Always replace the browser location, to remove OAuth details from back navigation
                    history.replaceState({}, document.title, redirectLocation); 
                    $(window).trigger("hashchange");
                }
            }
        }        
    }
    public async getUserInfo(): Promise<UserInfo | null> {

        const user = await this._userManager.getUser();
        
        if (user && user.profile) {
            if (user.profile.given_name && user.profile.family_name) {
                user.profile
                return {
                    givenName: user.profile.given_name,
                    familyName: user.profile.family_name,
                    
                };
            }
        }

        return null;
    }

    public async getAccessToken(redirectOnMissingToken: boolean = true): Promise<string> {

        // On most calls we just return the existing token from HTML5 storage
        const user = await this._userManager.getUser();
        if (user && user.access_token) {
            return user.access_token;
        }

        // Trigger a login redirect if there is no access token, and terminate the API call gracefully
        if (redirectOnMissingToken) {
            await this._startLogin();
            throw ErrorFactory.getFromLoginRequired();
        }
        else {
            return "";
        }
    }

    /*
    * Try to refresh an access token
    */
    public async refreshAccessToken(): Promise<string> {

        // Avoid an unnecessary refresh attempt when the app first loads
        if (HtmlStorageHelper.isLoggedIn) {
            console.log("refresh token");
            let user = await this._userManager.getUser();
            if (user && user.refresh_token && user.refresh_token.length > 0) {

                // Refresh the access token using a refresh token
                await this._performAccessTokenRenewalViaRefreshToken();

            } else  {

                // Use the traditional SPA solution, but it does not work in Cognito
                await this._performAccessTokenRenewalViaIframeRedirect();
            }

            // Return a renewed access token if found
            user = await this._userManager.getUser();
            if (user && user.access_token) {
                return user.access_token;
            }
        }

        // Otherwise trigger a login redirect
        await this._startLogin();
        // End the API request which brought us here with an error code that can be ignored
        throw ErrorFactory.getFromLoginRequired();
    }

    public async _startLogin(): Promise<void> {

        // First store the SPA's client side location
        const data = {
            hash: location.hash.length > 0 ? location.hash : '#',
        };

        try {
            console.log("start login");
            // Start a login redirect
            await this._userManager.signinRedirect({ state: data });

        } catch (e) {

            // Handle OAuth specific errors, such as CORS errors calling the metadata endpoint
            throw ErrorFactory.getFromLoginOperation(e, ErrorCodes.loginRequestFailed);
        }
    }


    /*
     * Redirect in order to log out at the authorization server and remove vendor cookies
     */
    public async startLogout(): Promise<void> {
        return this._startLogout();
    }

    /*
     * Handler logout notifications from other browser tabs
     */
    public async onExternalLogout(): Promise<void> {

        await this._userManager.removeUser();
        HtmlStorageHelper.isLoggedIn = false;
    }


    /*
    * Redirect in order to log out at the authorization server and remove the session cookie
    */
    private async _startLogout(): Promise<void> {

        try {
            // Otherwise use a standard end session request message
            await this._userManager.signoutRedirect();
            

            // Update the state for this app and notify other tabs
            HtmlStorageHelper.isLoggedIn = false;
            HtmlStorageHelper.multiTabLogout = true;

        } catch (e) {

            // Handle failures
            throw ErrorFactory.getFromLogoutOperation(e, ErrorCodes.logoutRequestFailed);
        }
    }

    /*
     * Try to refresh the access token by manually triggering a silent token renewal on an iframe
     * This will fail if there is no authorization server session cookie yet
     * It will also fail in the Safari browser
     * It may also fail if there has been no top level redirect yet for the current browser session
     */
    private async _performAccessTokenRenewalViaIframeRedirect(): Promise<void> {

        try {

            // Redirect on an iframe using the Authorization Server session cookie and prompt=none
            // This instructs the Authorization Server to not render the login page on the iframe
            // If the request fails there should be a login_required error returned from the Authorization Server
            await this._userManager.signinSilent();

        } catch (e: any) {

            if (e.error === ErrorCodes.loginRequired) {

                // Clear token data and our code will then trigger a new login redirect
                await this._userManager.removeUser();

            } else {

                console.log("failed to renew token");
                await this._userManager.removeUser();
                // Rethrow any technical errors
                throw ErrorFactory.getFromTokenError(e, ErrorCodes.tokenRenewalError);
            }
        }
    }

    /*
     * It is not recommended to use a refresh token in the browser, even when stored only in memory, as in this sample
     * The browser cannot store a long lived token securely and malicious code could potentially access it
     * Cognito provides no option to disable refresh tokens for SPAs
     */
    private async _performAccessTokenRenewalViaRefreshToken(): Promise<void> {

        try {

            // The library will use the refresh token grant to get a new access token
            await this._userManager.signinSilent();

        } catch (e: any) {

            // When the session expires this will fail with an 'invalid_grant' response
            if (e.error === ErrorCodes.sessionExpired) {

                // Clear token data and our code will then trigger a new login redirect
                await this._userManager.removeUser();

            } else {

                console.log("failed to renew token");
                await this._userManager.removeUser();
                // Rethrow any technical errors
                throw ErrorFactory.getFromTokenError(e, ErrorCodes.tokenRenewalError);
            }
        }
    }

    ///*
    // * Handle logout notifications from other browser tabs
    // */
    //private _onStorageChange(event: StorageEvent): void {

    //    if (HtmlStorageHelper.isMultiTabLogoutEvent(event)) {

    //        this!.onExternalLogout();
    //        location.hash = '#loggedout';
    //    }
    //}


}