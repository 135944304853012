import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./news-single-view.html";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { UmbracoApiClient } from "../ts/clients/umbraco-api-client";
import { News } from "../ts/models/news";
import * as moment from "moment";
import { MainLayout } from "./main-layout";

@injectable()
export class NewsSingleView {
    private _apiClient: UmbracoApiClient;
    private _translationService: TranslationService;
    private _translations: any;

    public constructor(ApiClient: UmbracoApiClient,
        translationService: TranslationService,
        private currentUserAccessor: CurrentUserAccessor) {        
        this._apiClient = ApiClient;
        this._translationService = translationService;
        this._translations = null;
    }

    public async load(idNovice: string, layout: MainLayout): Promise<void> {
        try {
            if (idNovice) { 
                var response = await this._apiClient.getNovicaByUrl(idNovice);
                if (response) {
                    layout.pageTitle = response.Naslov;
                    layout.htmlTitle = response.Naslov;
                }
                await this._renderData(response);
                var url = window.location.href;
                $("#shareButton").attr("href", "https://www.facebook.com/sharer/sharer.php?u=" + url);
            }
        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _renderData(data: News): Promise<void> {

        // Build a view model from the API data
        this._translations = this._translationService.currentTranslations;
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };

        var currentUser = await this.currentUserAccessor.getUser();

        data.Vsebina = this.unEscape(data.Vsebina);
        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            "imageKuponUrl": "../img/icon-kuponi.png",
            "imageKodaUrl": "../img/koda.jpg",
            "news": data,
            "formators": formators,
            translations: this._translations,
            currentUser: currentUser
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);

        // Set SEO
        $("#meta-description")[0].setAttribute("content", data.SeoDescription);
        $("#meta-og-title")[0].setAttribute("content", data.SeoTitle);
        $("#meta-og-description")[0].setAttribute("content", data.SeoDescription);
        $("#meta-og-image")[0].setAttribute("content", data.SeoImage);
        $("#meta-twitter-title")[0].setAttribute("content", data.SeoTitle);
        $("#meta-twitter-description")[0].setAttribute("content", data.SeoDescription);
        $("#meta-twitter-image")[0].setAttribute("content", data.SeoImage);
        var currentUrl = window.location.href;
        $("#meta-og-url")[0].setAttribute("content", currentUrl);
        $("#meta-twitter-url")[0].setAttribute("content", currentUrl);
    }  

    private unEscape(htmlStr: string): string {
        htmlStr = htmlStr.replace(/&lt;/g, "<");
        htmlStr = htmlStr.replace(/&gt;/g, ">");
        htmlStr = htmlStr.replace(/&quot;/g, "\"");
        htmlStr = htmlStr.replace(/&#39;/g, "\'");
        htmlStr = htmlStr.replace(/&amp;/g, "&");
        return htmlStr;
    }
}
