import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { ErrorLoadingView } from "../../views/error-loading-view";
import { CurrentUserAccessor } from "../utilities/current-user-accessor";
import { NewsSingleView } from "../../views/news-single-view";
import { UmbracoApiClient } from "../clients/umbraco-api-client";

@injectable()
export class NewsSinglePage extends PageBase
{   
    public name = "NewsSinglePage";
    private _apiClient: UmbracoApiClient;
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _homeView: NewsSingleView;
    private _translationService: TranslationService;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,        
        @inject("UmbracoApiClient") apiClient: UmbracoApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._router = router;
        this._translationService = translationService;
        this._notyf = notyf;
        this._apiClient = apiClient;
        this._homeView = new NewsSingleView(this._apiClient, translationService, currentUserAccessor);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (url.pathname != "" && !url.pathname.startsWith("/novica/") && !/^[0-9]+$/.test(url.pathname.replace("/novica/",""))) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            mainLayout.pageTitle = "Novica";
            mainLayout.htmlTitle = "Novica";
            await this._homeView.load(url.pathname.replace("/novica/", ""), mainLayout);
        } catch (e) {
            // Report failures
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}