import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { PostsResponse } from "../models/social-media-posts-response";

@injectable()
export class CuratorApiClient extends ApiClientBase {
    public name = "CuratorApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }   

    public async getSocialMediaPostsFacebook(skip: number, take: number, maxString: number): Promise<Array<PostsResponse>> {
        return await this._callApi('/curator/social-media/posts/facebook/' + skip + '/' + take + '/' + maxString, 'GET', null, false, "");
    }

    public async getSocialMediaPostsInstagram(skip: number, take: number, maxString: number): Promise<Array<PostsResponse>> {
        return await this._callApi('/curator/social-media/posts/instagram/' + skip + '/' + take + '/' + maxString, 'GET', null, false, "");
    }
} 
