import { inject, injectable, interfaces } from "inversify";
import * as Mustache from "mustache";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { TranslationService } from "../translation-service";
import htmlTemplate from "./page-not-found.html";

@injectable()
export class PageNotFound extends PageBase {
    public name = "AccountsPage";
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _translationService: TranslationService;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("TranslationService") translationService: TranslationService
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._translationService = translationService;
    }

    public async execute(): Promise<boolean> {
        var translations = this._translationService.currentTranslations;
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        mainLayout.pageTitle = translations.PageNotFound;
        mainLayout.htmlTitle = translations.PageNotFound;
        mainLayout.showHeader();
        //mainLayout.profileSidebarViewHide();

        var html = Mustache.render(htmlTemplate, {})
        $('#main').html(html);

        return false;
    }

}