import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";

@injectable()
export class HomeApiClient extends ApiClientBase {
    public name = "HomeApiClient";
    public constructor(

        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async get(): Promise<any> {
        return await this._callApi('/home/get', 'GET') as any;
    } 
}