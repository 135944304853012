import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { ErrorLoadingView } from "../../views/error-loading-view";
import { CurrentUserAccessor } from "../utilities/current-user-accessor";
import { HomeApiClient } from "../clients/home-api-client";
import { RegistracijaDrugiKorakView } from "../../views/registracija-drugi-korak-view";
import { UserApiClient } from "../clients/users-api-client";

@injectable()
export class RegistracijaDrugiKorak extends PageBase {
    public name = "RegistracijaDrugiKorak";
    private _homeApiClient: HomeApiClient;
    private _userApiClient: UserApiClient;
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _homeView: RegistracijaDrugiKorakView;
    private _translationService: TranslationService;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;
    private _currentUserAccessor: CurrentUserAccessor;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("HomeApiClient") homeApiClient: HomeApiClient,
        @inject("UserApiClient") userApiClient: UserApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._router = router;
        this._translationService = translationService;
        this._currentUserAccessor = currentUserAccessor;
        this._notyf = notyf;
        this._homeApiClient = homeApiClient;
        this._userApiClient = userApiClient;
        this._homeView = new RegistracijaDrugiKorakView(this._homeApiClient, this._userApiClient, translationService, this._notyf);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (url.pathname != "" && !url.pathname.startsWith("/registracija-drugi-korak")) {
            return true;
        }
        $("#footer-view").hide();

        var currentUser = await this._currentUserAccessor.getUser(true);
        if (!currentUser || currentUser && currentUser.korakRegistracije != 2) {
            return true;
        }

        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            mainLayout.pageTitle = "Registracija";
            mainLayout.htmlTitle = "Registracija";
            await this._homeView.load();
        } catch (e) {
            // Report failures
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}