import { injectable } from "inversify";
import * as Mustache from "mustache";
//import "bootstrap";
import { Authenticator } from "../../../skupno/src/ts/authentication/authenticator";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import template from "./header-view.html";
import editUserModal from "./edit-user-modal.html";
import { UserApiClient } from "../ts/clients/users-api-client";
// import { ProfileSaveModel } from "../ts/models/profile-basic-data-save-model";
import { Notyf } from "notyf";
import { FullscreenLoader } from "./fullscreen-loader";
import "select2";

@injectable()

export class HeaderView {
    private rootElement: HTMLElement;
    private titleElement: JQuery<HTMLElement> | null = null;
    private title: string = "";
    private isLoaded: boolean = false;
    private currentUser: any = null;

    constructor(rootElement: HTMLElement,
        private currentUserAccessor: CurrentUserAccessor,
        private authenticator: Authenticator,
        private userApiClient: UserApiClient,
        private notyf: Notyf,
        private loader: FullscreenLoader, 
      ) {
        this.rootElement = rootElement;
    }

    public setTitle(title: string) {
        if (!this.isLoaded) {
            this.title = title;
            return;
        }
        this.titleElement!.html(title);
    }

    public set visible(isVisible: boolean) {
        $(this.rootElement).toggle(isVisible);
    }
        
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            // Try to get data
            //TODO: prebrati podatke
            // Render new content
            await this._renderData();
            this.isLoaded = true;
            var self = this;

            var startScroll = $(window).scrollTop();
            $(window).on("scroll",(function () {
                var scrollTop = $(window).scrollTop();
                if (startScroll && scrollTop) {
                    if (startScroll < scrollTop && scrollTop > 0) {
                        $("#header-view").addClass("hide-menu");
                    }
                    else if (startScroll > scrollTop) {
                        $("#header-view").removeClass("hide-menu");
                    }
                }
                startScroll = scrollTop;
            }));
            $("#openMobileMenu").on("click", function () {
                $(".mobile-menu").toggleClass("show");
            });
            $("#closeMobileMenu").on("click", function () {
                $(".mobile-menu").toggleClass("show");
            });

            $(".navbar-nav .nav-item").on("click", function () {
                $(".mobile-menu").toggleClass("show");
            });
            $(".mobile-menu .logo-navbar").on("click", function () {
                $(".mobile-menu").toggleClass("show");
            });
            $(".ediProfileBtn").on("click", async function () {   
                await self._initEditProfile();
            });
           
            $('.dropdown-toggle').on('hide.bs.dropdown', function () {
                $("#userWrap").removeClass("active");
            });
            $('.dropdown-toggle').on('show.bs.dropdown', function () {
                $("#userWrap").addClass("active");
            });

            $("#runNavbar").on('click', () => {
                const urlFragment = "#/profil";
            
                if (urlFragment) {
                    const targetUrl = `${urlFragment}`;
                    if (window.location.hash === targetUrl) {
                        location.reload();
                    } else {
                        window.location.hash = targetUrl;
                    }
                }
            });

            $("#runNavbarOne").on('click', () => {
                const urlFragment = "#/profil";
            
                if (urlFragment) {
                    const targetUrl = `${urlFragment}`;
                    if (window.location.hash === targetUrl) {
                        location.reload();
                    } else {
                        window.location.hash = targetUrl;
                    }
                }
            });

            $(this.rootElement).find("[data-action=logout]").on("click", (ev) => {
                ev.preventDefault();
                this.authenticator.startLogout();
            })
        } catch (e) {
            console.log(e);
            // Clear previous content on error
            //$('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    //private initNotifModal() {
    //    var modalElement = $("body .modal-notification");
    //    var modalElementExist = modalElement.length > 0;


    //    var modalHtml = modalNotification;
    //    if (modalElementExist) {
    //        modalElement.replaceWith(modalHtml);
    //    }
    //    else {
    //        modalElement = $(modalHtml);
    //        $("body").append(modalElement);
    //    }
    //}

    private async _initSearchFields() {
        require('select2');
        let NazivDelodajalca = $(".employer-search-field"); 
        NazivDelodajalca.select2({
            ajax: (await this.userApiClient.companySearchOptions((settings) => {
                settings.url += "&" + jQuery.param({
                    search: $('.employer-search-field').data("select2").dropdown.$search.val()
                }, false);
                settings.error = function () { };
            })) as any,
            placeholder: "",
            minimumInputLength: 3,
            allowClear: false,
            dropdownParent: $("#employer-search-wrap"),
            language: {
                inputTooShort: function () {
                    return 'Za iskanje vnesite vsaj tri znake.';
                },
                noResults: function () {
                    return 'Ni rezultatov iskanja.'
                },
                searching: function () {
                    return 'Iskanje...'
                }
            }
        });
        var izbranNazivDelodajalca = $("[name=employer-search-hidden]").val() as string;
        NazivDelodajalca.append(new Option(izbranNazivDelodajalca, izbranNazivDelodajalca)).val(izbranNazivDelodajalca).trigger("change");
        NazivDelodajalca.on('select2:select', function () {
            
        }).on('select2:select', function () {
            $(this).blur();
        }).on('select2:open', function (e) {
            (document.querySelector(`[aria-controls="select2-${e.target.id}-results"]`) as HTMLElement).focus();
        });

        let Regija = $(".region-search-field");
        Regija.select2({
            ajax: (await this.userApiClient.regionSearchOptions((settings) => {
                settings.url += "&" + jQuery.param({
                    search: $('.region-search-field').data("select2").dropdown.$search.val()
                }, false);
                settings.error = function () { };
            })) as any,
            placeholder: "",
            minimumInputLength: 2,
            allowClear: false,
            dropdownParent: $("#region-search-wrap"),
            language: {
                inputTooShort: function () {
                    return 'Za iskanje vnesite vsaj dva znaka.';
                },
                noResults: function () {
                    return 'Ni rezultatov iskanja.'
                },
                searching: function () {
                    return 'Iskanje...'
                }
            }
        });
        var izbranaRegija = $("[name=region-search-hidden]").val() as string;
        Regija.append(new Option(izbranaRegija, izbranaRegija)).val(izbranaRegija).trigger("change");
        Regija.on('select2:select', function () {

        }).on('select2:select', function () {
            $(this).blur();
        }).on('select2:open', function (e) {
            (document.querySelector(`[aria-controls="select2-${e.target.id}-results"]`) as HTMLElement).focus();
        });

    }

    private async _initEditProfile(): Promise<void> {
        var self = this;
        this.currentUser = await this.currentUserAccessor.getUser();
        var genderOptions = [
            {
                val: null,
                title: "Izberi spol",
                sel: this.currentUser.gender == null
            },
            {
                val: 1,
                title: "Moški",
                sel: this.currentUser.gender == 1
            },
            {
                val: 2,
                title: "Ženski",
                sel: this.currentUser.gender == 2
            }
        ];

        const profileViewModel = {
            user: this.currentUser,
            region: this.currentUser.municipality == null ? "" : this.currentUser.municipality.name,
            employer: this.currentUser.company == null ? "" : this.currentUser.company.imeZavezanca,
            genderOptions: genderOptions,
        } as any;

        const modalHtml = Mustache.render(editUserModal, profileViewModel);
       
        $('#editProfileWrap').html(modalHtml);
        $('#editUser').modal("show");
        if(self.currentUser.avatarFilePath !== null && self.currentUser.avatarFilePath !== "") {
            $('.file-upload-wrapper').show();
            $('.user-logo').hide();
        }
        else {
            $('.file-upload-wrapper').hide();
            $('.user-logo').show();
        }

        $("#show-password").on("click", function () {
            var input = $("#password");
            if (input.attr("type") === "password") {
                input.attr("type", "text");
                $(this).attr("class", "fas fa-eye-slash");
            }
            else {
                input.attr("type", "password");
                $(this).attr("class", "fas fa-eye");
            }
        });
        $("#show-password-confirm").on("click", function () {
            var input = $("#confirmPassword");
            if (input.attr("type") === "password") {
                input.attr("type", "text");
                $(this).attr("class", "fas fa-eye-slash");
            }
            else {
                input.attr("type", "password");
                $(this).attr("class", "fas fa-eye");
            }
        });
        await this._initSearchFields();

        var formElement = $("#editProfileForm");
        $.validator.unobtrusive.parse(formElement);
        formElement.validate({
            rules: {
                password: {
                    minlength: 6
                },
                confirmPassword: {
                    minlength: 6,
                    equalTo: "#password"
                }
            }
        });

        $("#file-delete").on("click", () => {
            self.currentUser.avatarFilePath = null;
            $('.file-upload-wrapper').hide();
            $('.user-logo').show();
            (document.getElementById("profile-img-user")  as any ).value = null
        })

        //image prveive
        var imageElement = document.getElementById("profile-img-user") as HTMLImageElement;
        $("#file").on('change', (event) => {
                const file = (event.target as HTMLInputElement).files?.[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        imageElement.src = event.target?.result as string;
                    };
                    reader.readAsDataURL(file);
                    $('.file-upload-wrapper').show();
                    $('.user-logo').hide();
                }
        });
           
        formElement.on("submit",  (ev) =>{
            ev.preventDefault();
            if (!formElement.valid()) {
                return;
            }
            
            var formData = new FormData();

            // Append text inputs to formData
            formData.append("firstName", formElement.find("#firstname").val() as string);
            formData.append("lastName", formElement.find("#lastname").val()  as string);
            formData.append("gender", formElement.find("#gender").val()  as string);
            formData.append("region", $("#region-search").val() as string);
            formData.append("employer", $("#employer-search").val()  as string);
            formData.append("password", formElement.find("#password").val()  as string);
            formData.append("confirmPassword", formElement.find("#confirmPassword").val()  as string);

            if(self.currentUser.avatarFilePath === null)
            {
                 // Handle file input separately
                const fileInput: HTMLInputElement = document.getElementById("file") as HTMLInputElement;
                if (fileInput && fileInput.files) {
                    formData.append("file", fileInput.files[0]);
                }
            }
            else {
                formData.append("AvatarFilePath", self.currentUser.avatarFilePath  as string);
            }

            self.userApiClient.updateProfileFormData(formData)
                .then(async (data) => {
                    if (data && data.success) {
                        self.notyf.success("Podatki uspešno posodobljeni!");
                        $("#editUser").modal("hide");
                        self.loader.hide();
                        self.currentUser = await self.currentUserAccessor.getUser(false, true);
                        self.load();
                        window.location.reload();
                    }
                    else if (data && data.message) {
                        self.notyf.error(data.message);
                    }
                    else {
                        self.notyf.error("Prišlo je do napake!");

                    }
                })
                .catch(() => {
                    self.notyf.error("Prišlo je do napake");
                });
        });
    }

    private async _renderData(): Promise<void> {
        var viewModel;
        this.currentUser = await this.currentUserAccessor.getUser();

        if (this.currentUser != null) {
            viewModel = {
                user: {
                    title: "",
                    vloga: this.currentUser.vlogaTitle,
                    kratica: this.currentUser.inicialke,
                    email: this.currentUser.email,
                    authenticated: this.currentUser.isAuthenticated,
                    jeAdmin: this.currentUser.jeAdmin,
                    avatarFilePath: this.currentUser.avatarFilePath,
                }
            };
            if (this.currentUser.firstName) {
                viewModel.user.title += this.currentUser.firstName;
            }
            if (this.currentUser.lastName) {
                viewModel.user.title += " " + this.currentUser.lastName;
            }
        }
        else {
            viewModel = {
                user: null
            };
        } 

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        this.rootElement.innerHTML = html;
        this.titleElement = $(this.rootElement).find(".title h1");
        this.titleElement.html(this.title);

        $(this.rootElement).find("[data-action=logout]").on("click", (ev) => {
            ev.preventDefault();
            this.authenticator.startLogout();
        })               
        //if (currentUser.resetPassword) {
        //    this.changePasswordModal.show();
        //}
    }
}
